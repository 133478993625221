<template>
  <b-form
    class="px-2 pb-3 pt-2"
    @submit.prevent="submit"
  >
    <b-modal
      v-model="showLogModal"
      ok-only
      ok-title="Accept"
      centered
      size="lg"
      :hide-footer="true"
      title="Shipping Method"
    >
      <b-row class="breadcrumbs-top mb-2">
        <b-col cols="1"></b-col>
        <b-col cols="6" class="ml-12">
          <div
            v-for="(dispatch, keyD) of logCategory"
            :key="keyD"
            class="ml-"
          >
            <p><strong><u>Countries:</u></strong> {{ dispatch.country.join(' / ') }}</p>
            <ul>
              <li
                v-for="(solution, keyS) of dispatch.solutions"
                :key="keyS"
              >
                <strong><u>Log:</u></strong> {{ solution.logistic }} / <strong><u>Shipper:</u></strong> {{ solution.transport }} / <strong><u>Level:</u></strong> {{ solution.level }}
              </li>
            </ul>
          </div>
        </b-col>
      </b-row>
    </b-modal>
    <b-overlay
      :show="loading"
      rounded="sm"
    >
      <!-- <b-row>
        <b-col
          cols="2"
          offset="8"
          class="col align-self-end"
        >
          <b-avatar
            :variant="`light-primary`"
            size="45"
            class="cursor-pointer"
          >
            <img
              style="width: 30px;"
              :src="require(`@/assets/images/icons/barcode.png`)"
              @click="getBarcode()"
            >
          </b-avatar>
        </b-col>
        <b-col
          cols="2"
          offset="8"
          class="col align-self-end"
        >
          <b-avatar
            :variant="`light-success`"
            size="45"
            class="cursor-pointer"
          >
            <img
              style="width: 30px;"
              :src="require(`@/assets/images/icons/barcode.png`)"
              @click="getBarcodeOne()"
            >
          </b-avatar>
        </b-col>
      </b-row> -->
      <b-row>
        <b-col
          v-for="(attribute, a) in product.attributes"
          :key="a"
          md="6"
        >
          <template v-if="attribute.name === 'couleur'">
            <b-form-group
              :label="renderName(attribute.name)"
              label-for="mc-first-name"
            >
              <v-select
                v-model="form[attribute.name]"
                :dir="'ltr'"
                :options="attribute.values"
                label="couleur"
                transition=""
              >
                <template #option="{ couleur }">
                  <div class="d-flex justify-content-between align-items-center">
                    <span>{{ renderColor(couleur) }}</span>
                    <div
                      class="shadow-md border-2 border-white"
                      :style="`border-radius:50%; width: 18px; height: 18px; background-color: #${couleur}`"
                    />
                  </div>
                </template>
              </v-select>
            </b-form-group>
          </template>
          <b-form-group
            v-else
            :label="renderName(attribute.name)"
            label-for="mc-first-name"
          >
            <v-select
              v-model="form[attribute.name]"
              :dir="'ltr'"
              :label="attribute.name"
              :options="product.id === 129882 && productType === 'woosb' && attribute.name === 'Quantité' ? [attribute.values[0]] : attribute.values.filter(v => v)"
              transition=""
            />
          </b-form-group>
        </b-col>
        <!-- <div v-for="(attribute, a) in product.attributes" :key="a">
          <p class="font-action text-green-300 font-light mb-2">{{ attribute.name }}</p>
          <Select v-model="form[attribute.name]" :placeholder="attribute.name" full>
            <SelectOption v-for="(option, o) in attribute.values" :key="o" :label="option" :value="option" />
          </Select>
        </div> -->
        <!-- reset and submit -->
      </b-row>
      <b-row>
        <b-col
          cols="6"
          class="d-flex align-items-center"
        >
          <b-form-checkbox
            v-model="isTakeAway"
          />
          <h6 class="font-weight-bolder ml-1">
            Pris sur place
          </h6>
        </b-col>
        <b-col
          v-if="(product.categories.find(c => c.slug === 'matelas') || product.id === 135426) && ![114351, 99140].includes(product.id)"
          cols="6"
          class="d-flex align-items-center"
        >
          <b-form-checkbox
            v-if="variation && variation.shipping_class !== 'mattresses-specific-sizes'"
            v-model="repriseAncienMatelas"
          />
          <h6 v-if="variation && variation.shipping_class !== 'mattresses-specific-sizes'" class="font-weight-bolder ml-1">
            Reprise ancien matelas
          </h6>
          <h6 v-else class="font-weight-bolder text-danger ml-1">
            La reprise et la livraison à l'étage ne sont disponibles dans cette taille.
          </h6>
        </b-col>
        <b-col
          v-if="product.categories.find(c => c.slug === 'sommier') && me._id !== '621f3bc4fbfd254ad1411628'"
          cols="6"
          class="d-flex align-items-center"
        >
          <b-form-checkbox
            v-model="repriseAncienSommier"
          />
          <h6 class="font-weight-bolder ml-1">
            Reprise ancien sommier
          </h6>
          <span
            v-b-tooltip.hover
            title="Les sommiers doivent être démontés pour être repris"
            variant="gradient-primary"
            class="ml-1 text-white bg-primary rounded-circle text-center cursor-pointer"
            style="width: 20px;"
          >
            ?
          </span>
        </b-col>
        <b-col
          v-if="product.id !== 101186 && product.categories.find(c => c.slug === 'sommier') && me._id !== '621f3bc4fbfd254ad1411628'"
          cols="6"
          class="d-flex align-items-center"
        >
          <b-form-checkbox
            v-model="montageSommier"
          />
          <h6 class="font-weight-bolder ml-1">
            Montage sommier (+69€)
          </h6>
        </b-col>
        <b-col
          v-if="product.categories.find(c => c.slug === 'canape')"
          cols="6"
          class="d-flex align-items-center"
        >
          <b-form-checkbox
            v-model="repriseAncienCanape"
          />
          <h6 class="font-weight-bolder ml-1">
            Reprise ancien canapé
          </h6>
        </b-col>
        <b-col
          v-if="[101186, 133733].includes(product.id) && me._id !== '621f3bc4fbfd254ad1411628'"
          cols="6"
          class="d-flex align-items-center"
        >
          <b-form-checkbox
            v-model="montageLitCoffre"
          />
          <h6 class="font-weight-bolder ml-1">
            Montage lit coffre (+99€)
          </h6>
        </b-col>
        <b-col
          cols="12"
          class="d-flex align-items-center"
        >
          <duo-offers :product="product" @change="e => addProduct = e" />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-if="productType !== 'woosb'"
          cols="3"
          class="mt-1 d-flex justify-content-center align-items-center"
        >
          <span><strong>Quantité: </strong></span>
          <b-form-input v-model="quantity" placeholder="Quantité" type="number" class="ml-2"></b-form-input>
        </b-col>
        <b-col v-if="product.id === 109416 && productType === 'woosb'"
          cols="9"
          class="mt-1"
        >
          <p class="font-action text-green-500 font-light mb-2">Combien de chaises ? </p>
          <div class="d-flex justify-content-between align-items-center">
            <img v-for="(chair, keyC) of [4, 6, 8]" :key="keyC" :src="require(`@/assets/images/icons/${chair}chaises${(chair / 2) === woosbQuantity ? '' : '-unselect'}.svg`)" class="h-24 lg:h-32 cursor-pointer ml-2" @click="woosbQuantity = (chair / 2)">
          </div>
          <p class="font-action text-green-500 font-light mt-4"><strong>Vous économisez {{ 125 * (woosbQuantity - 1) }}€</strong> en achetant {{ woosbQuantity * 2 }} chaises avec la table.</p>
        </b-col>
        <b-col
          cols="5"
          class="mt-1"
        >
          <b-button
            type="submit"
            variant="primary"
            class="mr-1"
            :disabled="(product.attributes && product.attributes.length > 0) && (!variation || !variation.id)"
          >
            Ajouter au panier
          </b-button>
        </b-col>
        <b-col
          v-if="product.id === 101186"
          cols="12"
          class="mt-1"
        >
          <p
            class="text-danger mt-1"
            style="font-size: 16px;"
          >
            <strong>Si les accès à votre domicile ou les parties communes ne permettent pas d'accéder à votre domicile, nous vous invitons à contacter au plus vite notre service client pour trouver une solution.Vous pouvez nous contacter par mail à hello@kipli.com ou par téléphone au 01 76 42 11 72.<br><br>Sans vérification et contact de votre part, si la livraison n'est pas réalisable, aucune alternative ne pourra vous être proposée et des frais pourront être retenu.</strong>
          </p>
        </b-col>
        <b-col
          cols="12"
          class="mt-1"
        >
          <p
            v-if="(product.attributes && product.attributes.length > 0) && (!variation || !variation.id)"
            class="text-danger mt-1"
          >
            Merci de remplir tous les champs pour ajouter ce produit au panier.
          </p>
          <!-- <p
            v-else
            class="text-success mt-1"
          >
            Vous pouvez désormais ajouter ce produit au panier.
          </p> -->
        </b-col>
      </b-row>
      <b-row
        v-if="variation"
        cols="12"
        class="d-flex align-items-center"
      >
        <b-col cols="12">
          <span v-html="variation.description" />
        </b-col>
        <b-col cols="12">
          <span :class="`text-${variation.inStock ? 'success' : 'danger'} mb-1`">{{ `${variation.inStock ? 'Produit en stock' : 'Attention ! Le produit n\'est actuellement pas en stock !'}` }}</span>
        </b-col>
        <b-col cols="12" v-if="variation.inStock && variation.stock && ((variation.stock.agediss + variation.stock.colisland - variation.stock.waiting) < 10)">
          <span v-if="realStock">Il y a {{ realStock }} articles en stock.</span>
          <span v-else :class="`text-${variation.inStock ? 'success' : 'danger'} mb-1`">Il y a {{ variation.stock.agediss + variation.stock.colisland - variation.stock.waiting }} articles en stock.</span>
        </b-col>
        <b-col v-else-if="quantityRemaining" cols="12">
          Réappro de {{ quantityRemaining.remaining }} unités prévu pour le {{ $moment(quantityRemaining.dateShipping).format('DD/MM/YYYY') }}
        </b-col>
      </b-row>
      <b-button
        v-if="logCategory"
        variant="info"
        class="btn-cart mt-1"
        size="sm"
        @click="showLogModal = true"
      >
        <span>Mode de livraison</span>
      </b-button>
      <!-- <a v-if="variation" :href="`https://kipli.com/fr/product/${product.slug}/?variation=${variation.id}`" target="_blank"> -->
        <b-button
          size="sm"
          variant="primary"
          class="btn-cart mt-1 ml-1"
          @click="copyLink(`/fr/product/${product.slug}/?variation=${variation.id}`)"
        >
          <span>Copier lien variation</span>
        </b-button>
      <!-- </a> -->
      <b-row v-if="product.variations.filter(v => !v.inStock)" class="mt-2">
        <h4>Références out of stock:</h4>
        <b-col
          xs="12"
          md="12"
          lg="12"
        >
          <b-table-lite
            responsive
            :items="product.variations.filter(v => !v.inStock)"
            :fields="[{key: 'combined', label: 'Variation'}, {key: 'inStock', label: 'En stock'}]"
          >
            <template #cell(combined)="data">
              <span v-for="[key, value] of Object.entries(data.item.combined)" :key="key">
                <span class="text-uppercase"><strong><u>{{ key }}:</u></strong></span> {{ value }}
              </span>
            </template>
            <template #cell(inStock)="data">
              <span class="text-uppercase" :class="`${data.item.inStock ? 'text-success' : 'text-danger'}`"><strong><u>{{ data.item.inStock ? 'Oui' : 'Non' }}</u></strong></span>
            </template>
          </b-table-lite>
        </b-col>
      </b-row>
    </b-overlay>
  </b-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  BRow, BCol, BButton, BForm, BFormGroup, BFormCheckbox, BFormInput, VBTooltip, BOverlay, BTableLite, BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import products from '@/utils/products'
import DuoOffers from '@components/orders/DuoOffers.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    vSelect,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BFormInput,
    DuoOffers,
    // BAvatar,
    BOverlay,
    BTableLite,
    BModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    productType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      variation: null,
      isTakeAway: false,
      repriseAncienMatelas: false,
      repriseAncienSommier: false,
      repriseAncienCanape: false,
      montageSommier: false,
      montageLitCoffre: false,
      woosbQuantity: 2,
      form: {},
      quantity: this.product.quantity || 1,
      quantityRemaining: null,
      addProduct: null,
      loading: false,
      realStock: null,
      showLogModal: false,
    }
  },
  computed: {
    ...mapGetters('admin', ['me']),
  },
  watch: {
    form: {
      deep: true,
      handler: 'watchForm',
    },
  },
  methods: {
    ...mapActions('cart', ['add']),
    renderName(e) {
      return products.selectName(e)
    },
    renderColor(e) {
      return products.colorName(e)
    },
    async submit() {
      const data = {
        ...this.product,
        ...this.form,
        quantity: this.product.id === 109416 && this.productType === 'woosb' ? this.woosbQuantity : this.quantity,
        isTakeAway: this.isTakeAway,
        repriseAncienMatelas: this.repriseAncienMatelas,
        repriseAncienSommier: this.repriseAncienSommier,
        repriseAncienCanape: this.repriseAncienCanape,
        montageSommier: this.montageSommier,
        montageLitCoffre: this.montageLitCoffre,
      }
      if (this.product.type === 'woosb') {
        data.variationId = this.variation.id
        data.price = this.product.price
      } else if (this.variation?.id) {
        data.variationId = this.variation.id
        data.sku = this.variation.sku
        data.price = this.variation.price
      } else {
        data.variationId = this.product.id
      }
      console.log(this.addProduct)
      if (this.addProduct) {
        const { product } = await this.$http.get(`/products/${this.addProduct?.data?.url}`)
        const productOffer = {
          ...product,
          title: product.title,
          variationId: this.addProduct?.data?.variationId,
          price: this.addProduct?.price,
          quantity: this.addProduct?.data?.quantity || 1,
          isOffer: this.addProduct?.data?.offer,
          linkWith: Number(this.variation?.id) || Number(this.product?.id),
        }
        this.add(productOffer)
      }
      this.$emit('add', data)
      this.form = {}
      this.variation = null
    },
    async getBarcode() {
      try {
        this.loading = true
        console.log(this.variation, this.product)
        const dataMapped = this.variation ? this.variation : this.product
        console.log(dataMapped)
        const { link } = await this.$http.post('/admin/barcode', { variation: { ...dataMapped, title: this.product.title, combined: dataMapped?.combined || [] } })
        window.open(link)
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    async getBarcodeOne() {
      try {
        this.loading = true
        console.log(this.variation, this.product)
        const dataMapped = this.variation ? this.variation : this.product
        console.log(dataMapped)
        const { barcodePng } = await this.$http.get(`/products/${dataMapped.sku}`)
        console.log(barcodePng)
        // window.open(link)
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    async watchForm() {
      const form = { ...this.form }
      // JSON.stringify(variation.combined) === JSON.stringify(form)
      this.variation = this.product.variations.find(variation => Object.keys(variation.combined).filter(key => variation.combined[key] === form[key]).length === this.product.attributes.length)
      if (this.variation?.sku) {
        const { stock, quantityRemaining, logCategory } = await this.$http.get(`/admin/logistic/${this.variation.sku}/stock`)
        this.realStock = stock
        this.quantityRemaining = quantityRemaining
        this.logCategory = logCategory
      } else {
        this.realStock = null
        this.quantityRemaining = null
      }
    },
    copyLink(link) {
      window.navigator.clipboard.writeText(link)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
